import React, { useRef, useState, useEffect } from "react"
import styled from 'styled-components';
import { graphql, Link, useStaticQuery } from "gatsby"
import Search from "../../assets/svg/search.svg"

import TechnologyLayout from "../../container/TechnologyLayout"

const SearchBar = styled.div`
  display: flex;
  margin-left: 50px;
  margin-bottom: 80px;
  
  @media screen and (max-width: 768px) {
    margin-left: 20px;
  }
`;

const SearchInput = styled.input`
  width: 300px;
  margin-right: 15px;
  
  @media screen and (max-width: 768px) {
    width: 250px;
  }
`;

const SearchButton = styled.button`
  width: 30px;
  height: 30px;
  margin: 0;
  padding: 0;
  border: 0;
  cursor: pointer;
  background-color: var(--first-color);
  
  :hover {
    transform: scale(1.1);
  }
`

const SearchIcon = styled(Search)`
  width: 30px;
  height: 30px;
`;

const PerformanceContainer = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  flex-wrap: wrap;
  
  @media screen and (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

const Performance = styled(Link)`
  width: 300px;
  height: 200px;
  display: inline-block;
  position: relative;
  margin-right: 50px;
  margin-bottom: 50px;
  transition: transform .1s;
  cursor: pointer;
  color: black;
  text-decoration: none;
  
  :hover {
    transform: scale(1.05);
  }
  
  ::before, ::after {
    content: "";
    width: 100%;
    position: absolute;
  }
  
  ::before {
    top: 0;
    left: 0;
    border-top: 7px solid black;
  }
  
  ::after {
    bottom: 0;
    right: 0;
    border-bottom: 7px solid black;
  }
  
  :nth-child(3n) {
    margin: 0;
  }
  
  @media screen and (max-width: 768px) {
    margin: 0;
    width: 100%;
    height: 120px;
    border-bottom: 2px solid black;
    
    ::before, ::after {
      border: 0;
    }
    
    :hover {
      transform: none;
    }
    
    :first-child {
      border-top: 2px solid black;
    }
  }
`;

const PerformanceTitle = styled.div`
  font-size: 15px;
  font-weight: bold;
  width: 200px;
  height: 100px;
  margin: auto;
  padding-top: 20px;
  
  @media screen and (max-width: 768px) {
    font-size: 17px;
    padding-top: 10px;
    width: calc(100% - 50px);
    height: 50px;
  }
`;

const PerformanceClient = styled.div`
  font-size: 12px;
  width: 200px;
  margin: auto;
  padding-top: 30px;
  
  @media screen and (max-width: 768px) {
    font-size: 14px;
    width: calc(100% - 25px);
    height: 30px;
    padding: 0;
  }
`;

const PerformanceTerm = styled.div`
  font-size: 12px;
  width: 200px;
  margin: auto;
  padding-top: 5px;
  
  @media screen and (max-width: 768px) {
    padding: 0;
    font-size: 14px;
    width: calc(100% - 25px);
    height: 30px;
  }
`;

const PerformanceButton = styled.button`
  color: white;
  line-height: 40px;
  text-align: center;
  background-color: var(--first-color);
  height: 40px;
  width: 100%;
  border: 0;
  cursor: pointer;
`;



function PerformancePage () {
  const [count, setCount] = useState(9);
  const [search, setSearch] = useState('');
  const searchInput = useRef();
  const {
    allPerformanceListJson: {
      edges : performance
    }
  } = useStaticQuery(graphql`
      query {
          allPerformanceListJson {
              edges {
                  node {
                      id
                      start_date
                      end_date
                      name
                      client
                  }
              }
          }
      }
  `)

  const handleClickMoreButton = () => {
    if(count + 9 > performance.length) {
      setCount(performance.length)
      sessionStorage.setItem('count', performance.length)
    } else {
      sessionStorage.setItem('count', count + 9)
      setCount(count + 9)
    }
  }

  const handleClickSearchButton = () => {
    setSearch(searchInput.current.value)
  }

  useEffect(() => {
    if(window.sessionStorage) {
      let storedCount = sessionStorage.getItem('count');
      setCount(storedCount ? Number(storedCount) : 9);
    }
  }, [])

  return (
    <TechnologyLayout title="시공 실적">
      <SearchBar>
        <SearchInput
          ref={searchInput}
          placeholder="검색할 시공명을 입력하세요" />
        <SearchButton onClick={handleClickSearchButton}>
          <SearchIcon />
        </SearchButton>
      </SearchBar>
      <PerformanceContainer>
        {performance ?
          search ? (
            performance
              .filter(({node}) => node.name.includes(search))
              .map(({node}) => (
              <Performance
                key={node.id} 
                to={`/technology/performance/${node.id}`}>
                <PerformanceTitle>
                  {node.name}
                </PerformanceTitle>
                <PerformanceClient>
                  발주처: {node.client}
                </PerformanceClient>
                <PerformanceTerm>
                  공사기간: {node.start_date} ~ {node.end_date}
                </PerformanceTerm>
              </Performance>
            )
          )
        ) : (
            performance
              .slice(0, count).map(({node}) => (
              <Performance
                key={node.id} 
                to={`/technology/performance/${node.id}`}>
                <PerformanceTitle>
                  {node.name}
                </PerformanceTitle>
                <PerformanceClient>
                  발주처: {node.client}
                </PerformanceClient>
                <PerformanceTerm>
                  공사기간: {node.start_date} ~ {node.end_date}
                </PerformanceTerm>
              </Performance>
            )
          )
        )
        : null}
      </PerformanceContainer>
      {(performance.length > count && !search) ? (
        <PerformanceButton onClick={handleClickMoreButton}>
          더 보기
        </PerformanceButton>
      ) : null
      }
    </TechnologyLayout>
  )
}

export default PerformancePage